<template>
    <div class="maps">
        <iframe width="100%" height="100%" style="border:0"
                :src="'https://www.google.com/maps/embed/v1/place?q=' + placeId + '&key=' + apiKey"
                allowfullscreen>
        </iframe>
    </div>
</template>

<script>
    export default {
        name: "MapsView",
        props: {
            placeId: String,
            apiKey: String
        }
    }
</script>

<style scoped>
    .maps {
        width: 100%;
    }
</style>
