<template>
    <div class="outer">
        <b-row align-h="center">
            <div class="title mb-4"><h3>Du hittar oss här!</h3></div>
            <maps-view
                    place-id="place_id:ChIJY2X9hGueX0YRt-3peFMl_-E"
                    :api-key="GOOGLE_MAPS_API_KEY"
                    class="maps"/>
        </b-row>
    </div>
</template>

<script>
    import MapsView from "./MapsView";
    export default {
        name: "OurLocation",
        components: {MapsView},
        data: function () {
            return {
                GOOGLE_MAPS_API_KEY: 'AIzaSyB94gyGWLzawE4vQkRhiHhjfFGrmNcnhyY'
            };
        },
    }
</script>

<style scoped>
    .outer {
        width: 100%;
        padding: 0 15px;
    }

    .maps {
        height: 500px;
    }

    .title {
        text-align: center;
    }
</style>
