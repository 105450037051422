<template>
    <div>
        <b-container>
            <b-row>
                <b-col md="7">
                    <h3>Fastighetsskötsel.</h3>
                    <p>
                        Behöver ni ha någon som tar hand om kontoret eller lokalen? Vi på Rato AB sköter fastighetsskötseln till flera stora aktörer och kan hjälpa er att få ordning och reda på fastighetsskötseln så ni slipper tänka på det.<br>
                        <br>
                        Ofta är fastighetsskötseln något man vill glömma så man kan fokusera på sin egen verksamhet, vi hjälper er så ni slipper!<br>
                        <br>
                        Kontakta oss via frågeformuläret så hjälper vi er.<br>
                        <br>
                        Telefon: <a href="tel:+46734444003">+46 73 444 4003</a><br>
                    </p>
                </b-col>
                <b-col md="5">
                    <contact-form/>
                </b-col>

                <!--                Certifications-->
                <b-col cols="12" class="mt-5">
                    <b-row align-v="center" align-h="center">
                        <b-col cols="4" lg="2" class="item">
                            <img src="@/assets/images/rryo5faifir3v5x2br9m.jpg" alt="MTR" class="img-fluid certification-logo">
                        </b-col>
                        <b-col cols="4" lg="2" class="item">
                            <img src="@/assets/images/nedladdning.png" alt="nedladdning" class="img-fluid certification-logo">
                        </b-col>
                        <b-col cols="4" lg="2" class="item">
                            <img src="@/assets/images/CapMan_logo_grey_rgb_2018-01.png" alt="CapMan" class="img-fluid certification-logo">
                        </b-col>
                        <b-col cols="4" lg="2" class="item">
                            <img src="@/assets/images/imageedit102627581546.png" alt="Catella" class="img-fluid certification-logo">
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
        <our-location class="mt-5"/>
    </div>
</template>

<script>
    import ContactForm from "../ContactForm";
    import OurLocation from "../OurLocation";
    export default {
        name: "AboutMaintenance",
        components: {OurLocation, ContactForm}
    }
</script>

<style scoped>
</style>
